<template>
  <div class="card w-100" style="height: 100vh">
    <div
      class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
    >
      <b-img
        style="max-width: 160px"
        src="@/assets/images/logo/Secucy-logo.png"
        alt="logo"
      />
      <p class="mt-1 text-center">
        Verifying your credentials. Please wait while we authenticate your
        account
      </p>
      <b-spinner variant="primary" class="mt-25" />
    </div>
  </div>
</template>

<script>
import { BSpinner, BImg } from "bootstrap-vue";

export default {
  components: {
    BSpinner,
    BImg,
  },
};
</script>

<style>
</style>